import env from '../env';
/**
 * Gets a resume by website slug
 */
export const getResumeBySlug = async (slug) => {
  let fetchOptions = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  };

  const url = `${env.BASE_API}/getResumeBySlug/${slug}`;
  const response = await fetch(url, fetchOptions);

  if (response.status !== 200) {
    const text = await response.text();
    throw new Error(text);
  }

  return response.json();
};

export const getQueryStringValue = (key) => {
  return decodeURIComponent(
    window.location.search.replace(
      new RegExp(
        '^(?:.*[&\\?]' +
          encodeURIComponent(key).replace(/[.+*]/g, '\\$&') +
          '(?:\\=([^&]*))?)?.*$',
        'i',
      ),
      '$1',
    ),
  );
};
