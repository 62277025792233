import React, { useEffect, useState } from 'react';
import {
  Websites,
  WebsiteError,
  Loading,
} from '@evolutiontechno/resumedone-components';

import { getQueryStringValue, getResumeBySlug } from './api/helpers';
import env from './env';

function App() {
  const [source, setSource] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  const getResume = async () => {
    try {
      const slug =
        env.NODE_ENV === 'production'
          ? window.location.hostname
          : getQueryStringValue('slug');
      if (!slug) {
        return setError(true);
      }
      let file = await getResumeBySlug(slug);
      setSource(file);
      setLoading(false);
    } catch (e) {
      setError(true);
      setLoading(false);
    }
  };

  const handleRedirect = () => {
    window.location = 'https://resumedone.co';
  };

  useEffect(() => {
    getResume();
  }, []);

  if (error)
    return (
      <WebsiteError
        title="Personal Website Unavailable"
        redirect={handleRedirect}
        description="This website is no longer publicly available or might have been deleted by the user."
        CTATitle="Go To Homepage"
      />
    );

  if (loading) return <Loading />;

  return (
    <div>
      <Websites source={source} />
    </div>
  );
}

export default App;
